<template>
  <!-- <footer class="footer mt-auto py-3 text-center">
    <div class="container">
      <span class="text-center">&copy; {{currentYear}} Φυτουκλάκι</span>
    </div>
  </footer> -->
  <footer class="footer">
    <div class="container">
      ΤρελοςΦοιτητης
      <sup>
        <span class="logocolour">ΤΦ</span>
      </sup>
      2010-{{ currentYear }}
      <!-- <a rel="license" href="https://creativecommons.org/licenses/by-nc/4.0/" target="_blank">
        <img
          alt="Creative Commons Licence"
          title="Creative Commons Licence"
          style="border-width: 0"
          height="31"
          width="88"
          src="https://i.creativecommons.org/l/by-nc/4.0/88x31.png" />
      </a> -->
    </div>
  </footer>
</template>

<script>
  export default {
    data() {
      return {
        currentYear: new Date().getFullYear(),
      };
    },
  };
</script>

<style>
  /* .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #77c778;
  color: #fff;
} */

  .logocolour {
    color: #80ffff;
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    /* line-height: normal; */
    /* text-transform: none; */
    background-color: #025587;
    text-align: center;
    color: #fff;
    letter-spacing: 0.1em;
    text-decoration: none;
    margin: 0;
    font:
      normal 100% 'century gothic',
      arial,
      sans-serif;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
